<template>
  <section id="mock" class="mock main milk-bg">
    <div class="container">
      <SwipersContent
        :id="1"
        :promos="promos"
        :img-config="img_config"
        :gold-config="gold_config"
        @click-button-gold="onMoreBrand"
        class="promo-1"
      />
      <div id="info" class="mock-info">
        <div class="flex-fs-sb">
          <h2 class="view-1023-not">{{ lang('q11') }}</h2>
          <div></div>
          <div>
            <div
              :class="{
                'flex-col': device !== 'mobile',
                'flex-c': device === 'mobile',
              }"
            >
              <FlagRotate class="mock-info--logo" />
              <h2 class="view-1023">{{ lang('q11') }}</h2>
            </div>
            <div class="gold-bg">
              <p v-html="lang('q12')" class="big" />
            </div>
            <p v-html="lang('q13')" class="small" />
            <p class="small">
              {{ lang('q14') }}
            </p>
          </div>
        </div>
      </div>
      <SwipersContent
        :id="2"
        :promos="promos_prod"
        :img-config="img_config_prod"
        :gold-config="gold_config_prod"
        :titles-config="titlesConfig"
        @click-button-gold="onSubscribeScroll"
        class="promo-2"
      />
      <div v-show="false" id="subscribe" class="mock-subscribe">
        <h1>{{ lang('q22') }}</h1>
        <p class="text">
          {{ lang('q23_1') }}<br v-if="device === 'mobile'" />
          {{ lang('q23_2') }}<br v-if="device !== 'mobile'" />
          {{ lang('q23_3') }}<br v-if="device === 'mobile'" />
          {{ lang('q23_4') }}
        </p>
        <form action="subscribe" method="post" @submit.prevent="onSubscribe">
          <div class="mock-subscribe-form">
            <div :class="{ hidden: hiddenIcon || email.email }" class="inpt">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                  stroke="#3C3E4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C14.252 22 16.33 21.256 18.001 20"
                  stroke="#3C3E4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 8V12C16 13 16.6 15 19 15C21.4 15 22 13 22 12"
                  stroke="#3C3E4A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="inpt-up-holder">{{ lang('q24') }}</p>
              <input
                @input="email.error = ''"
                @focus="hiddenIcon = true"
                @blur="hiddenIcon = false"
                v-model="email.email"
                type="text"
                :placeholder="hiddenIcon ? '' : lang('q24')"
                :class="{ 'inpt-error': email.error }"
              />
              <transition name="fade">
                <div v-if="email.error" class="error">
                  {{ email.error }}
                </div>
              </transition>
            </div>
            <button
              v-if="device !== 'mobile'"
              :disabled="disabledButton"
              class="btn btn-normal btn-dark-gradient"
            >
              {{ lang('q27') }}
            </button>
          </div>
          <div class="checkboxes flex-col flex-fs-fs">
            <Checkbox
              @change-cb="isAgree = $event"
              :checked="isAgree"
              class="mock-subscribe-checkbx"
            >
              <template #text>
                <p v-html="lang('q25')" />
              </template>
            </Checkbox>
            <Checkbox
              @change-cb="isAgree2 = $event"
              :checked="isAgree2"
              class="mock-subscribe-checkbx"
            >
              <template #text>
                <p>
                  {{ lang('q26') }}
                </p>
              </template>
            </Checkbox>
          </div>
          <button
            v-if="device === 'mobile'"
            :disabled="disabledButton"
            class="btn btn-normal btn-dark-gradient"
          >
            {{ lang('q27') }}
          </button>
        </form>
      </div>
      <section id="subscribe" class="subscribe">
        <div class="container">
          <div class="subscribe-box">
            <div class="subscribe-box__cell">
              <h1>{{ lang('q22') }}</h1>
            </div>
            <div class="subscribe-box__cell"></div>
            <div class="subscribe-box__cell">
              <p class="text">
                {{ lang('q23_1') }}<br v-if="device === 'mobile'" />
                {{ lang('q23_2') }}<br v-if="device !== 'mobile'" />
                {{ lang('q23_3') }}<br v-if="device === 'mobile'" />
                {{ lang('q23_4') }}
              </p>
              <p
                v-if="windowWidth > 1024"
                class="text-small"
                v-html="lang('q58')"
              ></p>
            </div>

            <div class="subscribe-box__cell">
              <form
                action="subscribe"
                method="post"
                @submit.prevent="onSubscribe"
              >
                <div class="subscribe-form">
                  <div
                    :class="{ hidden: hiddenIcon || email.email }"
                    class="inpt"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                        stroke="#3C3E4A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C14.252 22 16.33 21.256 18.001 20"
                        stroke="#3C3E4A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 8V12C16 13 16.6 15 19 15C21.4 15 22 13 22 12"
                        stroke="#3C3E4A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p class="inpt-up-holder">{{ lang('q24') }}</p>
                    <input
                      @input="email.error = ''"
                      @focus="hiddenIcon = true"
                      @blur="hiddenIcon = false"
                      v-model="email.email"
                      type="text"
                      :placeholder="hiddenIcon ? '' : lang('q24')"
                      :class="{ 'inpt-error': email.error }"
                    />
                    <transition name="fade">
                      <div v-if="email.error" class="error">
                        {{ email.error }}
                      </div>
                    </transition>
                  </div>
                  <button
                    v-if="device !== 'mobile'"
                    :disabled="disabledButton"
                    class="btn btn-normal btn-dark-gradient"
                  >
                    {{ lang('q27') }}
                  </button>
                </div>
                <div class="checkbxs flex-col flex-fs-fs">
                  <Checkbox
                    @change-cb="isAgree = $event"
                    :checked="isAgree"
                    class="subscribe-checkbx"
                  >
                    <template #text>
                      <p v-html="lang('q25')" />
                    </template>
                  </Checkbox>
                  <Checkbox
                    @change-cb="isAgree2 = $event"
                    :checked="isAgree2"
                    class="subscribe-checkbx"
                  >
                    <template #text>
                      <p>
                        {{ lang('q26') }}
                      </p>
                    </template>
                  </Checkbox>
                </div>
                <button
                  v-if="device === 'mobile'"
                  :disabled="disabledButton"
                  class="btn btn-normal btn-dark-gradient"
                >
                  {{ lang('q27') }}
                </button>
              </form>
              <p
                v-if="windowWidth <= 1024"
                class="text-small"
                v-html="lang('q58')"
              ></p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <teleport to="#modal">
      <!-- <Modal v-if="mockProductModal" name="mockProductModal" default-size>
        <template #modal-content>
          <div class="modal-content flex-col">
            <h1 class="modal-title" v-html="modal.title" />
            <img :src="require(`@/assets/img/${modal.img}`)" />
            <p class="main">
              {{ modal.description }}
            </p>
            <p class="grey">БАД выпускается в виде жевательных таблеток.</p>
            <button
              @click="onScroll('#subscribe')"
              class="btn btn-gold btn-normal-2"
            >
              УЗНАЙ ПЕРВЫМ О СТАРТЕ ПРОДАЖ
            </button>
          </div>
        </template>
      </Modal> -->
      <Modal v-if="mockSubscribeModal" name="mockSubscribeModal" default-size>
        <template #modal-content>
          <div class="modal-confirm flex-col flex-c-c">
            <Logo />
            <h1>{{ success.title }}</h1>
            <p v-html="success.text" />
            <button
              @click="onScroll('#mock')"
              class="btn btn-gold btn-normal-2"
            >
              {{ lang('q40') }}
            </button>
          </div>
        </template>
      </Modal>
      <Modal
        v-if="mockFeedbackModal"
        name="mockFeedbackModal"
        default-size
        :class-box="'grey-bg'"
      >
        <template #modal-content>
          <form action="question" @submit.prevent="onQuestion">
            <div class="feedback flex-col flex-c-c">
              <h1 class="modal-title">{{ lang('q41') }}</h1>
              <p>
                {{ lang('q42') }}
              </p>
              <div class="grid24 feedback-grid">
                <InputComponent
                  v-model.trim="feedback.name"
                  :placeholder="lang('q43')"
                  class="a"
                  :error="feedbackErrors.name"
                  @clear-error="feedbackErrors.name = ''"
                />
                <InputComponent
                  v-model="feedback.email"
                  type="email"
                  class="b"
                  :placeholder="lang('q24')"
                  :error="feedbackErrors.email"
                  @clear-error="feedbackErrors.email = ''"
                />
                <TextComponent
                  v-model.trim="feedback.question"
                  :limit="limit"
                  :placeholder="lang('q44')"
                  class="c"
                  :error="feedbackErrors.question"
                  @clear-error="feedbackErrors.question = ''"
                />
              </div>
              <div class="checkboxes flex-col flex-fs-fs">
                <Checkbox
                  @change-cb="isAgreeFeedback = $event"
                  @clear-error="feedbackErrors.agree = ''"
                  :checked="isAgreeFeedback"
                  :error="feedbackErrors.agree"
                  class="feedback-checkbx"
                >
                  <template #text>
                    <p v-html="lang('q25')" />
                  </template>
                </Checkbox>
                <Checkbox
                  @change-cb="feedback.is_ads_agreement = $event"
                  :checked="feedback.is_ads_agreement"
                  class="feedback-checkbx"
                >
                  <template #text>
                    <p>
                      {{ lang('q26') }}
                    </p>
                  </template>
                </Checkbox>
              </div>
              <button
                @click="onQuestion"
                :disabled="disabledButton"
                type="button"
                class="btn btn-gold btn-normal-2"
              >
                {{ lang('q45') }}
              </button>
              <p class="text-small" v-html="lang('q58')"></p>
            </div>
          </form>
        </template>
      </Modal>
      <Modal v-if="errorModal" name="errorModal" default-size>
        <template #modal-content>
          <div class="modal-confirm flex-col flex-c-c">
            <Logo />
            <h1>{{ lang('q46') }}</h1>
            <p>{{ lang('q47') }}</p>
            <button
              @click="onCloseModal({ name: 'errorModal', payload: false })"
              class="btn btn-gold btn-normal-2"
            >
              {{ lang('q48') }}
            </button>
          </div>
        </template>
      </Modal>
    </teleport>
  </section>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'
import { mapState, mapMutations } from 'vuex'
import { isEmail } from '@/utils/validate'
import Checkbox from '../components/Checkbox.vue'
import FlagRotate from '../components/FlagRotate.vue'
import Logo from '../components/Logo.vue'
import Modal from '../components/Modal.vue'
import SwipersContent from '../components/SwipersContent.vue'
import InputComponent from '../components/Input.vue'
import TextComponent from '../components/Textarea.vue'
import { lang } from '@/lang'

import { postSubscribe, postQuestion } from '@/api/mock'

export default {
  components: {
    SwipersContent,
    FlagRotate,
    Checkbox,
    Modal,
    Logo,
    InputComponent,
    TextComponent,
  },
  name: 'MockPage',
  data() {
    return {
      lang,
      apiMethods: {
        subscribe: postSubscribe,
        question: postQuestion,
      },
      disabledButton: false,
      validateEmail: isEmail,
      scrollTo: scroller(),
      isAgree: false,
      isAgreeFeedback: false,
      isAgree2: true,
      hiddenIcon: false,
      // верхний
      img_config: {
        width: 840,
      },
      gold_config: {
        width: 510,
        height: 300,
        class: 'gold',
      },
      // promos: [
      //   {
      //     title: 'GOLD’N&nbsp;APOTHEKA',
      //     subtitle: lang('q3'),
      //     img: 'promo-6-mock.png',
      //     gold: {
      //       title: 'Инновационные технологии',
      //       text: 'Специалисты бренда разрабатывают новые продукты с учетом результатов современных научных исследований.',
      //       link_name: 'ПОДРОБНЕЕ О БРЕНДЕ',
      //     },
      //   },
      //   {
      //     title: 'GOLD’N&nbsp;APOTHEKA',
      //     subtitle: lang('q3'),
      //     img: 'promo-2-mock.png',
      //     gold: {
      //       title: 'База бренда — завод в Германии',
      //       text: 'Ингредиенты для GOLD’N&nbsp;APOTHEKA поставляют партнеры из Германии, Франции, Литвы, Бельгии, Словении и Словакии и других стран',
      //       link_name: 'ПОДРОБНЕЕ О БРЕНДЕ',
      //     },
      //   },
      //   {
      //     title: 'GOLD’N&nbsp;APOTHEKA',
      //     subtitle: lang('q3'),
      //     img: 'promo-3-mock.png',
      //     gold: {
      //       title: 'Смарт-подход',
      //       text: 'Состав и комбинации продуктов подбираются с учетом особенностей здоровья и образа жизни потребителей.',
      //       link_name: 'ПОДРОБНЕЕ О БРЕНДЕ',
      //     },
      //   },
      // ],
      // нижний
      img_config_prod: {
        width: 840,
      },
      gold_config_prod: {
        width: 510,
        height: 300,
        class: 'dark-gold',
      },
      titlesConfig: {
        title_size: { fontSize: '48px', lineHeight: '59px' },
        subtitle_size: { fontSize: '16px', lineHeight: '20px' },
        title_size_mobile: { fontSize: '24px', lineHeight: '29px' },
        subtitle_size_mobile: { fontSize: '16px', lineHeight: '20px' },
      },
      // promos_prod: [
      //   {
      //     id: 1,
      //     title:
      //       '<span style="color: #0B0E1D">Для красоты<br></span>и здоровья',
      //     subtitle:
      //       'Заказывайте витамины, минералы и другие БАДы GOLD’N APOTHEKA на <a target="_blank" href="https://www.ozon.ru/seller/r-farm-kosmetiks-78745/?miniapp=seller_78745" style="color: #D1B36C; text-decoration: underline;">Ozon</a>, <a target="_blank" href="https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=%D1%80+%D1%84%D0%B0%D1%80%D0%BC+%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D1%81#c96720494" style="color: #D1B36C; text-decoration: underline;">Wildberries</a>, <a target="_blank" href="https://market.yandex.ru/catalog--vitaminy-i-mineraly/25603210/list?srnum=2&was_redir=1&rt=9&rs=eJwzkgpgrGLhmHmYdRYjf3p-Top6nkJiQX5JRmp2IgBxYAjX&suggest=1&suggest_type=search&text=gold%27n%20apotheka&hid=90521&local-offers-first=0" style="color: #D1B36C; text-decoration: underline;">Яндекс.Маркет</a> и <a target="_blank" href="https://sbermegamarket.ru/catalog/?q=%D1%80%20%D1%84%D0%B0%D1%80%D0%BC%20%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D1%81#?filters=%7B%222B0B1FF4756D49CF84B094522D57ED3D%22%3A%5B%22GOLD%E2%80%99N%20APOTHEKA%22%5D%7D" style="color: #D1B36C; text-decoration: underline;">СберМегаМаркет</a> уже сейчас',
      //     img: 'promo-7-mock.png',
      //     gold: {
      //       title: '<span style="color: #D1B36C">Базовая линейка</span>',
      //       text: 'Моно- и поливитамины, необходимые для сохранения здоровья и красоты, поддержания высокого жизненного тонуса',
      //       link_name: 'Узнать больше',
      //     },
      //   },
      //   {
      //     id: 2,
      //     title:
      //       '<span style="color: #0B0E1D">Для красоты<br></span>и здоровья',
      //     subtitle:
      //       'Заказывайте витамины, минералы и другие БАДы GOLD’N APOTHEKA на <a target="_blank" href="https://www.ozon.ru/seller/r-farm-kosmetiks-78745/?miniapp=seller_78745" style="color: #D1B36C; text-decoration: underline;">Ozon</a>, <a target="_blank" href="https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=%D1%80+%D1%84%D0%B0%D1%80%D0%BC+%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D1%81#c96720494" style="color: #D1B36C; text-decoration: underline;">Wildberries</a>, <a target="_blank" href="https://market.yandex.ru/catalog--vitaminy-i-mineraly/25603210/list?srnum=2&was_redir=1&rt=9&rs=eJwzkgpgrGLhmHmYdRYjf3p-Top6nkJiQX5JRmp2IgBxYAjX&suggest=1&suggest_type=search&text=gold%27n%20apotheka&hid=90521&local-offers-first=0" style="color: #D1B36C; text-decoration: underline;">Яндекс.Маркет</a> и <a target="_blank" href="https://sbermegamarket.ru/catalog/?q=%D1%80%20%D1%84%D0%B0%D1%80%D0%BC%20%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D1%81#?filters=%7B%222B0B1FF4756D49CF84B094522D57ED3D%22%3A%5B%22GOLD%E2%80%99N%20APOTHEKA%22%5D%7D" style="color: #D1B36C; text-decoration: underline;">СберМегаМаркет</a> уже сейчас',
      //     img: 'promo-8-mock.jpg',
      //     gold: {
      //       title: '<span style="color: #D1B36C">Витамины по показаниям</span>',
      //       text: 'БАДЫ, разработанные под определенные функциональные потребности, могут оказывать целевую поддержку организму',
      //       link_name: 'Узнать больше',
      //     },
      //   },
      // ],
      // modal: {
      //   title: '',
      //   img: '',
      //   description: '',
      // },
      email: {
        email: '',
        error: '',
      },
      success: {
        title: '',
        text: '',
      },
      feedbackErrors: {
        email: '',
        agree: '',
        name: '',
        question: '',
      },
      feedback: {
        name: '',
        email: '',
        question: '',
        is_ads_agreement: true,
      },
      limit: 800,
    }
  },
  computed: {
    ...mapState({
      mockProductModal: (state) => state.modals.mockProductModal,
      mockSubscribeModal: (state) => state.modals.mockSubscribeModal,
      mockFeedbackModal: (state) => state.modals.mockFeedbackModal,
      errorModal: (state) => state.modals.errorModal,
    }),
    promos() {
      return [
        {
          title: 'GOLD’N&nbsp;APOTHEKA',
          subtitle: this.lang('q3'),
          img: 'promo-6-mock.png',
          gold: {
            title: this.lang('q4'),
            text: this.lang('q5'),
            link_name: this.lang('q19'),
          },
        },
        {
          title: 'GOLD’N&nbsp;APOTHEKA',
          subtitle: this.lang('q3'),
          img: 'promo-2-mock.png',
          gold: {
            title: this.lang('q7'),
            text: this.lang('q8'),
            link_name: this.lang('q19'),
          },
        },
        {
          title: 'GOLD’N&nbsp;APOTHEKA',
          subtitle: this.lang('q3'),
          img: 'promo-3-mock.png',
          gold: {
            title: this.lang('q9'),
            text: this.lang('q10'),
            link_name: this.lang('q19'),
          },
        },
      ]
    },
    promos_prod() {
      return [
        {
          id: 1,
          title: this.lang('q15'),
          subtitle: this.lang('q16'),
          img: 'promo-7-mock.png',
          gold: {
            title: this.lang('q17'),
            text: this.lang('q18'),
            link_name: this.lang('q19'),
          },
        },
        {
          id: 2,
          title: this.lang('q15'),
          subtitle: this.lang('q16'),
          img: 'promo-8-mock.jpg',
          gold: {
            title: this.lang('q20'),
            text: this.lang('q21'),
            link_name: this.lang('q19'),
          },
        },
      ]
    },
  },
  watch: {
    isAgree(val) {
      if (val) this.email.error = ''
    },
  },
  methods: {
    ...mapMutations({
      setModalProps: 'modals/setModalProps',
      onCloseModal: 'modals/onCloseModal',
    }),
    registerSubscribe(recaptchaToken) {
      this.disabledButton = true
      this.apiMethods
        .subscribe({ email: this.email.email }, recaptchaToken)
        .then(() => {
          this.disabledButton = false
          this.success.title = this.lang('q49')
          this.success.text = this.lang('q50')
          this.setModalProps({
            name: 'mockSubscribeModal',
            value: !this.mockSubscribeModal,
          })
          this.clearFields(this.email)
        })
        .catch((err) => {
          console.log(err)
          this.disabledButton = false
          this.setModalProps({
            name: 'errorModal',
            value: !this.errorModal,
          })
        })
    },
    registerQuestion(recaptchaToken) {
      this.disabledButton = true
      this.apiMethods
        .question(this.feedback, recaptchaToken)
        .then(() => {
          this.disabledButton = false
          this.onCloseModal({ name: 'mockFeedbackModal', payload: false })
          this.success.title = this.lang('q51')
          this.success.text = this.lang('q52')
          this.setModalProps({
            name: 'mockSubscribeModal',
            value: !this.mockSubscribeModal,
          })
          this.clearFields(this.feedback)
        })
        .catch((err) => {
          console.log(err)
          this.disabledButton = false
          this.onCloseModal({ name: 'mockFeedbackModal', payload: false })
          this.setModalProps({
            name: 'errorModal',
            value: !this.errorModal,
          })
        })
    },
    onMoreBrand() {
      this.scrollTo('#info', {
        offset: -28,
      })
    },
    onScroll(id) {
      this.onCloseModal({ name: 'mockSubscribeModal', payload: false })
      this.$nextTick(() => {
        this.scrollTo(id)
      })
    },
    onSubscribeScroll() {
      this.scrollTo('#subscribe', {
        offset: -28,
      })
    },
    // setModal(id) {
    //   if (!id) return
    //   if (id == 1) {
    //     this.modal.title =
    //       '<span style="color:#D1B36C">Vitamin C</span><br>Lemon'
    //     this.modal.img = 'modal-1-mock.png'
    //     this.modal.description =
    //       'Монопродукт «Витамин С жевательный со вкусом лимона» содержит витамин С в высокой концентрации. Его действие направлено не только на укрепление иммунитета. Витамин С стимулирует выработку коллагена, который повышает упругость кожи и сокращает морщины.'
    //   } else {
    //     this.modal.title =
    //       '<span style="color:#D1B36C">Vitamin C</span><br>Lemon+Ginger'
    //     this.modal.img = 'modal-2-mock.png'
    //     this.modal.description =
    //       'Комплекс «Имбирь и витамин С со вкусом лимона» повышает уровень содержания витаминов С и В2 в организме. В2 — «витамин красоты» — отвечает за чистую кожу, крепкие ногти и густые волосы. А еще он помогает уменьшить нагрузку на нервную систему и зрение. Витамин С положительно воздействует на иммунитет, однако его концентрация в этом комплексе ниже, чем в моновитаминах.'
    //   }
    // },
    async onSubscribe() {
      if (this.validateEmail(this.email.email) === true && this.isAgree) {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('subscribe')
        this.registerSubscribe(token)
      } else if (!this.email.email) {
        this.email.error = this.lang('q53')
      } else if (this.validateEmail(this.email.email) !== true) {
        this.email.error = this.validateEmail(this.email.email)
      } else {
        this.email.error = this.lang('q54')
      }
    },
    async onQuestion() {
      let isError
      if (!this.feedback.name) {
        this.feedbackErrors.name = this.lang('q55')
        isError = true
      }
      if (!this.feedback.email) {
        this.feedbackErrors.email = this.lang('q53')
        isError = true
      } else if (this.validateEmail(this.feedback.email) !== true) {
        this.feedbackErrors.email = this.validateEmail(this.feedback.email)
        isError = true
      }
      if (!this.feedback.question) {
        this.feedbackErrors.question = this.lang('q56')
        isError = true
      }
      if (this.feedback.question.length > this.limit) {
        this.feedbackErrors.question = this.lang('q59') + this.limit
        isError = true
      }
      if (!this.isAgreeFeedback) {
        this.feedbackErrors.agree = this.lang('q54')
        isError = true
      }

      if (isError) return

      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('question')
      this.registerQuestion(token)
    },
  },
}
</script>

<style lang="scss" scoped>
.mock {
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 914px;
    background: url('../assets/icons/spiral.svg') no-repeat;
    top: 329px;
    left: 50%;
    transform: translateX(-50%);
    background-position-x: center;
    @include mw(1023) {
      top: 867px;
      height: 836px;
      background-size: 1322px 836px;
      background-position-x: 62%;
    }
    @include mobile {
      display: none;
    }
  }
  @include mobile {
    padding-top: 24px;
    padding-bottom: 56px;
  }
  .promo-1 {
    margin-bottom: 142px;
    @include mobile {
      margin-bottom: 84px;
    }
  }
  .promo-2 {
    margin-bottom: 170px;
    @include mw(1023) {
      margin-bottom: 80px;
    }
    @include mobile {
      margin-bottom: 56px;
    }
  }
  &-info {
    max-width: 905px;
    width: 100%;
    margin: 0 auto 100px auto;
    transform: translateX(10%);
    margin-right: 10%;
    @include mw(1023) {
      max-width: 426px;
      transform: translateX(9%);
      margin-right: 14%;
      margin: 0 auto 80px auto;
    }
    @include mobile {
      margin-bottom: 32px;
      margin-right: auto;
      transform: none;
    }
    @include mw(1023) {
      .flex-fs-sb {
        margin-right: 0px;
      }
    }
    .view-1023 {
      display: none;
      @include mw(1023) {
        display: block;
      }
    }
    .view-1023-not {
      @include mw(1023) {
        display: none;
      }
    }
    h2 {
      font-weight: 800;
      font-size: 24px;
      line-height: 140%;
      text-transform: uppercase;
      color: $gold;
      margin-top: 112px;
      @include mw(1023) {
        margin-bottom: 32px;
        margin-top: 0;
      }
      @include mobile {
        margin-bottom: 64px;
      }
    }
    .gold-bg {
      background: $gold-gradient;
      padding: 16px;
      margin-bottom: 32px;
      max-width: 552px;
      @include mobile {
        margin-bottom: 24px;
      }
    }
    p.big {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: $main-grey;
      @include mobile {
        font-size: 16px;
      }
    }
    p.small {
      font-size: 16px;
      line-height: 140%;
      max-width: 619px;
      margin-bottom: 32px;
      @include mobile {
        font-size: 14px;
        margin-bottom: 24px;
      }
    }
    &--logo {
      margin-bottom: 80px;
      @include mw(1023) {
        margin-bottom: 72px;
      }
      @include mobile {
        margin-right: 69px;
      }
    }
  }
}
.modal-content {
  align-items: flex-start;
  h1.modal-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
  }
  img {
    margin-bottom: 24px;
  }
  p.main {
    font-size: 16px;
    line-height: 150%;
    color: $black-main;
    margin-bottom: 16px;
    @include mobile {
      font-size: 14px;
    }
  }
  p.grey {
    font-size: 14px;
    line-height: 150%;
    color: $black-main;
    opacity: 0.5;
    margin-bottom: 32px;
    @include mobile {
      margin-bottom: 24px;
    }
  }
  .btn {
    width: 100%;
  }
  button {
    @include mobile {
      font-size: 14px;
      width: 100%;
      padding: 14px 0;
    }
  }
}
.modal-confirm {
  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin: 24px 0;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 32px;
  }
  button {
    @include mobile {
      font-size: 14px;
      width: 100%;
      padding: 14px 0;
    }
  }
}
.feedback {
  margin-top: 16px;
  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    line-height: 140%;
    max-width: 481px;
    text-align: center;
    margin-bottom: 24px;
  }
  button {
    @include mobile {
      font-size: 14px;
      width: 100%;
      padding: 14px 0;
    }
  }
  &-grid {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'a b'
      'c c';
    margin-bottom: 27px;
    @include mobile {
      grid-template-columns: 1fr;
      grid-template-areas:
        'a'
        'b'
        'c';
    }
  }
  .checkboxes {
    margin: 0 auto;
    width: fit-content;
    & .feedback-checkbx:first-child {
      margin-bottom: 22px;
      @include mobile {
        margin-bottom: 37px;
      }
    }
  }
  &-checkbx {
    width: 100%;
    margin-bottom: 35px;
    p {
      text-align: left;
      font-size: 12px;
      margin-bottom: 0;
      // white-space: nowrap;
      @include tablet {
        white-space: normal;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  p.text-small {
    font-size: 12px;
    line-height: 140%;
    color: $main-grey;
    margin-top: 30px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    @include tablet {
      font-size: 12px;
      margin-top: 24px;
    }
  }
}

.subscribe {
  padding: 40px 0;
  @include mobile {
    padding: 40px 0;
  }
}
.subscribe-box {
  background: $brown-gradient;
  padding: 40px 24px 60px 24px;
  display: grid;
  text-align: left;
  grid-template-columns: minmax(200px, 468px) minmax(200px, 468px);
  grid-template-rows: auto;
  justify-content: center;
  column-gap: 85px;
  @include mw(1024) {
    grid-template-columns: 468px;
  }
  @include mobile {
    padding: 40px 24px;
    text-align: center;
    grid-template-columns: auto;
  }
  h1 {
    font-weight: 800;
    font-size: 48px;
    line-height: 140%;
    color: $main-grey;
    margin-bottom: 24px;
    @include mobile {
      font-size: 24px;
    }
  }
  p.text {
    font-size: 16px;
    line-height: 140%;
    color: $main-grey;
    max-width: 468px;
    @include mw(1024) {
      margin-bottom: 24px;
    }
    @include mobile {
      font-size: 14px;
    }
  }
  p.text-small {
    font-size: 12px;
    line-height: 140%;
    color: $main-grey;
    margin-top: 30px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    @include tablet {
      font-size: 12px;
      margin-top: 24px;
    }
  }
  .subscribe-form {
    display: flex;
    justify-content: center;
    margin-bottom: 27px;
    @include mobile {
      margin-bottom: 36px;
    }
    @include desktop {
      justify-content: start;
    }
    .inpt {
      background: $white;
      border: 1px solid $white;
      box-sizing: border-box;
      width: calc(262px + 32px);
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s;
      svg {
        opacity: 1;
        transition: all 0.3s;
      }
      &-up-holder {
        font-size: 10px;
        line-height: 140%;
        position: absolute;
        opacity: 0;
        color: $input-grey;
      }
      &.hidden {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        svg {
          opacity: 0;
          position: absolute;
        }
        .inpt-up-holder {
          opacity: 0.8;
          position: relative;
        }
        input {
          margin-left: 0;
          padding: 0;
        }
      }
      &:hover {
        border: 1px solid $gold;
      }
      &:hover input::placeholder {
        color: $black-main;
      }
      @include mobile {
        width: 100%;
      }
      input {
        font-size: 14px;
        width: 100%;
        margin-left: 8px;
        padding: 14px 0;
        &::placeholder {
          transition: all 0.3s;
        }
        &.inpt-error {
          color: $input-error;
        }
      }
      .error {
        font-size: 12px;
        position: absolute;
        color: $input-error;
        // opacity: 0.8;
        left: 0;
        top: calc(100% + 3px);
        white-space: nowrap;
        @include mobile {
          white-space: normal;
          text-align: left;
        }
      }
    }
  }
  .btn {
    @include mobile {
      width: 100%;
    }
  }
  .subscribe-checkbx {
    justify-content: center;
    @include mobile {
      margin-bottom: 24px;
    }
    p {
      font-size: 12px;
      line-height: 140%;
      color: $main-grey;
      text-align: left;
      a {
        text-decoration: underline;
      }
    }
  }
  .checkbxs {
    width: fit-content;
    margin: 0 auto;
    & .subscribe-checkbx:first-child {
      margin-bottom: 22px;
      @include mobile {
        margin-bottom: 19px;
      }
    }
  }
}
</style>

<style lang="scss">
.subscribe,
.feedback {
  a {
    text-decoration: underline;
  }
}
</style>
