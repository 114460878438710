import request from '@/utils/request'

export function postSubscribe(data, token) {
  return request({
    url: `/api/subscribe/`,
    method: 'post',
    headers: {
      'x-recaptcha-response': token,
    },
    data,
  })
}

export function postQuestion(data, token) {
  return request({
    url: `/api/faq/question/`,
    method: 'post',
    data,
    headers: {
      'x-recaptcha-response': token,
    },
  })
}
