import Cookies from 'js-cookie'

const state = () => ({
  menuMobile: false,
  location: false,
  cookieMessage: Cookies.get('cookieMessage'),
  mockProductModal: false,
  mockSubscribeModal: false,
  mockFeedbackModal: false,
  mockLanguagesModal: false,

  errorModal: false,
  feedbackModal: false,
  successFeedbackModal: false,
  successSubscribeModal: false,

  classOpen: false,
  delayAnimate: 300,
})

const mutations = {
  setModalProps(state, payload) {
    state[payload.name] = payload.value
  },
  setCookieMessage(state) {
    state.cookieMessage = Cookies.set('cookieMessage', true)
  },
  setClassOpen(state) {
    state.classOpen = true
  },
  onCloseModal(state, payload) {
    state.classOpen = false
    setTimeout(() => {
      state[payload.name] = payload.value
    }, state.delayAnimate)
  },
}

export default {
  state,
  mutations,
  namespaced: true,
}
