export default {
  q1: 'Health and beauty vitamins',
  q2: 'Contact us',
  q3: 'Expert in beauty and health products',
  q4: 'Innovative technology',
  q5: 'Brand specialists create new products based on fresh scientific research results.',
  q6: 'Learn more about the brand',
  q7: 'The brand’s base is a factory in&nbsp;Germany',
  q8: 'Ingredients for GOLD’N&nbsp;APOTHEKA products are imported from Germany, France, Lithuania, Belgium, Slovenia, Slovakia and other countries.',
  q9: 'Smart system',
  q10: 'Product ingredients and combinations are chosen based on consumers’ health issues and lifestyle.',
  q11: 'About the brand',
  q12: 'Harmony&nbsp;in&nbsp;everything: the philosophy of golden ratio is at the basis of GOLD’N&nbsp;APOTHEKA’s&nbsp;formulas',
  q13: 'The most popular dietary supplements are produced under the GOLD’N&nbsp;APOTHEKA brand. The first six products from the essentials line are already on sale.',
  q14: 'Soon the product collection will include superfoods, probiotics, mushroom complexes, collagen, essential mono- and multivitamins. More than 250 most popular ingredient combinations.',
  q15: '<span style="color: #0B0E1D">For beauty<br></span>and health',
  q16: `Order vitamins, minerals and other GOLD’N&nbsp;APOTHEKA dietary supplements at <a target="_blank" href="https://www.ozon.ru/seller/r-farm-kosmetiks-78745/?miniapp=seller_78745" style="color: #D1B36C; text-decoration: underline;">Ozon</a>, <a target="_blank" href="https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=%D1%80+%D1%84%D0%B0%D1%80%D0%BC+%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D1%81#c96720494" style="color: #D1B36C; text-decoration: underline;">Wildberries</a>, <a target="_blank" href="https://market.yandex.ru/catalog--vitaminy-i-mineraly/25603210/list?srnum=2&was_redir=1&rt=9&rs=eJwzkgpgrGLhmHmYdRYjf3p-Top6nkJiQX5JRmp2IgBxYAjX&suggest=1&suggest_type=search&text=gold%27n%20apotheka&hid=90521&local-offers-first=0" style="color: #D1B36C; text-decoration: underline;">Yandex.Market</a>, <a target="_blank" href="https://sbermegamarket.ru/catalog/?q=%D1%80%20%D1%84%D0%B0%D1%80%D0%BC%20%D0%BA%D0%BE%D1%81%D0%BC%D0%B5%D1%82%D0%B8%D0%BA%D1%81#?filters=%7B%222B0B1FF4756D49CF84B094522D57ED3D%22%3A%5B%22GOLD%E2%80%99N%20APOTHEKA%22%2C%22GOLD'N%20APOTHEKA%22%5D%7D" style="color: #D1B36C; text-decoration: underline;">SberMegaMarket</a> and <a target="_blank" href="https://www.eapteka.ru/goods/brand/gold_n_apotheka/" style="color: #D1B36C; text-decoration: underline;">EAPTEKA</a> now`,
  q17: '<span style="color: #D1B36C">Base Line</span>',
  q18: 'Mono- and multivitamins needed for maintaining your health, beauty and high vitality.',
  q19: 'Learn more',
  q20: '<span style="color: #D1B36C">Indications Line</span>',
  q21: 'Dietary supplements designed for specific functional needs and targeted body support.',
  q22: 'Keep up with us',
  q23_1: 'Subscribe to latest news and',
  q23_2: 'be the first to learn about our newest products',
  q23_3: 'and',
  q23_4: 'special offers',
  q24: 'E-mail',
  q25: 'By clicking the submit button below, I hereby consent to <a href="/GA_Personal_data_processing_consent.pdf" target="_blank">the processing of my personal data</a>',
  q26: 'By clicking the submit button below, I hereby agree to receive marketing and promotional e-mails',
  q27: 'Subscribe',
  q28: '<span style="color: #D1B36C">Find more about</span><br>our newest products',
  q29: 'LLC R-Farm Cosmetics',
  q30: 'Address: 119421, Moscow, Leninskiy avenue, 111, build. 1, floor 5, room 128',
  q31: 'PSRN: 1197746629948',
  q32: 'TIN: 7728489492',
  q33: '<a class="rules">Personal data processing policy</a>',
  q34: 'All rights reserved',
  q35: 'By using this website you agree to our <a href="/cookie" style="text-decoration:underline;">cookies policy</router-link>',
  q36: 'Confirm',
  q37: 'Main',
  q38: 'Cookie processing consent',
  q39: 'By continuing to use this website I consent to machine processing as well as processing without the use of automated means of my personal data (cookie files, user activity information, user equipment information, session date and time) by LLC WHY-NOT (hereinafter – the Company), registered office: 119633, Moscow, intracity territory Municipal district Novoperedelkino, Borovskoe highway, 18, bldg. 3, floor/premises 1/I, room/office 5/42. Specifically, I agree to the processing of my personal data using metric programs such as Yandex.Metrika, Google Analytics, Firebas Google, Tune, Amplitude, Segmento and others, in the following ways: collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, depersonalization, blocking, deletion, erasure, transfer (provision of access) to the Company’s Partners providing services under the above specified metric programs.<br><br>The Company processes personal data to the following ends: improvement of website operation, improvement of the Company’s products and services, clarification of user preferences, provision of targeted information on Company’s and Company Partners’ products and services. The consent given hereby is valid from the moment of its provision and during the entire period of website usage.<br><br>I hereby confirm that I was informed of the need to stop using this website/disable cookies in the browser settings in case I do not consent to the processing of my personal data using metric programs. ',
  q40: 'Home',
  q41: 'Do you have any questions left?',
  q42: 'We will gladly help you. Please contact us.',
  q43: 'Name',
  q44: 'Question',
  q45: 'SEND',
  q46: 'Oops! Something went wrong',
  q47: 'Refresh the page and try again',
  q48: 'Close',
  q49: 'Thank you for your subscription',
  q50: 'You are now subscribed to GOLD’N&nbsp;APOTHEKA newsletter.<br>You’ll be the first to know about our newest products, offers and restocks.',
  q51: 'Thank you!',
  q52: 'Your message is sent to a GOLD’N&nbsp;APOTHEKA expert. You will receive a response to the provided e-mail address shortly.',
  q53: 'Type your e-mail here',
  q54: 'Accept our personal data processing policy',
  q55: 'Type your name here',
  q56: 'Type your question here',
  q57: 'Incorrect e-mail',
  q58: `This site is protected by reCAPTCHA and 
  <a
    href="https://policies.google.com/privacy?hl=ru"
    target="_blank"
    rel="noopener noreferrer"
    >Terms of Service apply</a
  ><br />
  and 
  <a
    href="https://policies.google.com/terms?hl=ru"
    target="_blank"
    rel="noopener noreferrer"
    >the Google Privacy Policy</a
  >.`,
  q59: 'The maximum number of characters is ',
}
