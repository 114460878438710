import { createRouter, createWebHistory } from 'vue-router'
import LayoutMock from '../LayoutMock.vue'
import Mock from '../views/Mock.vue'
import DenialOfResponsibility from '../views/DenialOfResponsibility.vue'
import DocumentCookie from '../views/DocumentCookie.vue'

const routes = [
  {
    path: '/',
    name: 'LayoutMock',
    component: LayoutMock,
    children: [
      {
        path: '',
        name: 'Mock',
        component: Mock,
      },
      {
        path: 'denial-of-responsibility',
        name: 'DenialOfResponsibility',
        component: DenialOfResponsibility,
      },
      {
        path: 'cookie',
        name: 'DocumentCookie',
        component: DocumentCookie,
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {
        left: 0,
        top: 0,
      }
    }
  },
})

export default router
