<template>
  <div class="checkbx-wrap">
    <div class="checkbx">
      <input
        type="checkbox"
        :checked="checked"
        @change="
          $emit('clear-error'), $emit('change-cb', $event.target.checked)
        "
      />
      <div class="checkbx-box"></div>
    </div>
    <slot name="text" />
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbx {
  position: relative;
  margin-right: 11px;
  input {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    &:checked + .checkbx-box {
      background: $gold-hover url('../assets/icons/checked-black.svg') no-repeat;
      background-size: 8px 6px;
      background-position: center center;
    }
  }
  &-wrap {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  &-box {
    width: 18px;
    height: 18px;
    background: $gold-hover;
    border: 2px solid $main-grey;
    border-radius: 4px;
  }
}
.error {
  position: absolute;
  font-size: 12px;
  color: $input-error;
  left: 0;
  top: calc(100% + 3px);
}
</style>
