<template>
  <div><img src="@/assets/img/flag.png" /></div>
</template>

<script>
export default {
  name: 'FlagRotate',
}
</script>

<style lang="scss" scoped>
div {
  margin-left: 33px;
  // margin-right: 110px;
  width: fit-content;
  position: relative;
  &::before {
    content: '';
    width: 98px;
    height: 100px;
    background: url('../assets/icons/brand-logo.svg') no-repeat;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
    animation: rotate360 30s linear infinite;
  }
  img {
    width: 32px;
    position: relative;
    z-index: 1;
  }
}
</style>
