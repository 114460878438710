<template>
  <button type="button" class="flex-c-c button" :class="{ reverse }">
    <svg
      :width="size ? size.w : 10"
      :height="size ? size.h : 18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L9 9L1 17"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Object, Number],
    },
  },
  name: 'ButtonNavigationSwiper',
}
</script>

<style lang="scss" scoped>
.button {
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid $milk-bg;
  transition: all 0.3s;
  &.reverse {
    transform: rotate(180deg);
  }
  &:hover {
    background: rgba(247, 244, 237, 0.1);
  }
  &:active {
    background: rgba(247, 244, 237, 0.2);
  }
  &.black {
    border: 1px solid $main-grey;
    svg path {
      stroke: $main-grey;
    }
    &:hover {
      background: rgba(46, 51, 57, 0.1);
    }
    &:active {
      background: rgba(46, 51, 57, 0.2);
    }
  }
}
</style>
