const breakpoints_window = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1152,
  xxl: 1400,
}

/**
 * Миксин добавляет глобальные переменные:
 * device {String} (mobile, tablet, desktop)
 * breakpoints {String} (xs, sm, md, lg, xl, xxl)
 */
export default {
  computed: {
    device() {
      if (
        this.breakpoints_window === 'xs' ||
        this.breakpoints_window === 'sm'
      ) {
        return 'mobile'
      } else if (this.breakpoints_window === 'md') {
        return 'tablet'
      }
      return 'desktop'
    },
  },
  data() {
    return {
      breakpoints_window: 'xs',
      windowWidth: 1440,
    }
  },
  created() {
    this.onResizeDevice()
    window.addEventListener('resize', this.onResizeDevice)
  },
  methods: {
    onResizeDevice() {
      const width = window.innerWidth
      this.windowWidth = width

      if (width <= breakpoints_window.sm) {
        this.breakpoints_window = 'sm'
      } else if (
        width > breakpoints_window.sm &&
        width < breakpoints_window.md
      ) {
        this.breakpoints_window = 'md'
      } else if (width >= breakpoints_window.md) {
        this.breakpoints_window = 'lg'
      }
    },
  },
}
