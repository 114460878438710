<template>
  <div class="modal" :class="{ show }">
    <a :href="toRussian">Русский</a>
    <button @click="onClick()" type="button">English</button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  nmae: 'ModalLanguages',
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 10)
    document.addEventListener('click', (e) => this.$emit('close', e), false)
  },
  unmounted() {
    this.show = false
    document.removeEventListener('click', (e) => this.$emit('close', e), false)
  },
  computed: {
    toRussian() {
      return process.env.VUE_APP_ROUTE_TO_RUSSIAN
    },
  },
  methods: {
    ...mapMutations({
      setLanguage: 'lang/setLanguage',
      setModalProps: 'modals/setModalProps',
    }),
    onClick() {
      // this.setLanguage(lang)
      this.setModalProps({
        name: 'mockLanguagesModal',
        value: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  padding: 24px;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s;
  &.show {
    opacity: 1;
  }
  button,
  a {
    font-weight: 500;
    display: inline-block;
    color: #0b0e1d;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 17px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
