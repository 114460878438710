<template>
  <router-link :style="{ height: hw + 'px', width: hw + 'px' }" to="/">
    <svg
      :width="hw"
      :height="hw"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4995 5.25554L13.9116 14.8417C8.29649 20.4568 8.03025 29.6246 13.3204 35.2778C16.0968 38.2461 19.8656 39.8798 23.9334 39.8798C27.8889 39.8798 31.5816 38.3239 34.3356 35.4973C39.7623 29.9324 39.6413 20.5277 34.0746 14.9593L28.9711 9.85586C28.8415 9.7262 28.6686 9.65532 28.4854 9.65532C28.3021 9.65532 28.1292 9.7262 27.9996 9.85586L18.3875 19.468C15.3033 22.5521 15.0509 27.4636 17.8135 30.6498C19.3539 32.4253 21.584 33.4453 23.9334 33.4453C26.2811 33.4453 28.5113 32.427 30.0534 30.6498C32.816 27.4636 32.5636 22.5521 29.4794 19.468L28.9106 18.8992C28.7637 18.7522 28.5683 18.671 28.3609 18.671C28.1534 18.671 27.9581 18.7522 27.8129 18.8992L23.0154 23.6966C22.6593 24.0527 22.6593 24.6319 23.0154 24.988L24.3051 26.2777C24.478 26.4506 24.7079 26.5456 24.9517 26.5456C25.1955 26.5456 25.4254 26.4506 25.5983 26.2794L28.086 23.7899C28.7015 25.4116 28.3229 27.2475 27.0729 28.4992C26.2362 29.3359 25.1211 29.7975 23.9352 29.7975C22.5469 29.7975 21.2625 29.163 20.4084 28.0583C19.0548 26.3053 19.2743 23.745 20.915 22.1044L28.4854 14.534L31.4157 17.4643C35.7273 21.7776 35.8569 28.736 31.7009 32.975C29.6419 35.0755 26.8828 36.2338 23.9334 36.2338C20.9841 36.2338 18.2267 35.0772 16.1677 32.9768C12.0117 28.7378 12.1379 21.7794 16.4529 17.4643L26.0806 7.83663C26.3434 7.57212 26.3434 7.14338 26.0806 6.87888L24.4555 5.25554C24.3276 5.12761 24.1582 5.05672 23.9767 5.05672C23.7969 5.05845 23.6257 5.12761 23.4995 5.25554ZM1.49714 24.0009C1.49714 11.5933 11.5916 1.49887 23.9991 1.49887C36.4084 1.49887 46.5029 11.5933 46.5029 24.0009C46.5029 36.4084 36.4084 46.5029 23.9991 46.5029C11.5933 46.5029 1.49714 36.4084 1.49714 24.0009ZM0 24.0009C0 37.2348 10.7669 48 23.9991 48C37.2313 48 48 37.2331 48 24.0009C48 10.7669 37.2331 0 23.9991 0C10.7652 0 0 10.7669 0 24.0009Z"
        fill="#D1B36C"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    hw: {
      type: String,
      default: '48',
    },
  },
}
</script>

<style lang="scss" scoped>
svg {
  path {
    transition: all 0.3s;
  }
  &:hover {
    path {
      fill: $hover-logo;
    }
  }
  &:active {
    path {
      fill: $active-logo;
    }
  }
}
</style>
