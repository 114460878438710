<template>
  <div :class="{ hidden: hiddenPlaceholder || modelValue }" class="inpt">
    <p class="inpt-up-holder">{{ placeholder }}</p>
    <input
      @keypress.enter="$emit('enter')"
      @input="
        $emit('clear-error'), $emit('update:modelValue', $event.target.value)
      "
      @focus="hiddenPlaceholder = true"
      @blur="hiddenPlaceholder = false"
      :value="modelValue"
      :type="type || 'text'"
      :placeholder="hiddenPlaceholder ? '' : placeholder"
      :class="{ 'inpt-error': error }"
    />
    <transition name="fade">
      <div v-if="error" class="error">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    error: {
      type: String,
    },
  },
  data() {
    return {
      hiddenPlaceholder: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.inpt {
  background: $white;
  border: 1px solid $white;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s;
  &-up-holder {
    font-size: 10px;
    line-height: 140%;
    position: absolute;
    opacity: 0;
    color: $input-grey;
    z-index: -1;
  }
  &.hidden {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .inpt-up-holder {
      opacity: 0.8;
      position: relative;
      z-index: 0;
    }
    input {
      padding: 0;
    }
  }
  &:hover {
    border: 1px solid $gold;
  }
  &:hover input::placeholder {
    color: $black-main;
  }
  @include mobile {
    width: 100%;
  }
  input {
    font-size: 14px;
    width: 100%;
    padding: 14px 0;
    @include mobile {
      font-size: 16px;
    }
    &::placeholder {
      transition: all 0.3s;
    }
    &::placeholder {
      color: $input-placeholder-grey;
    }
    &.inpt-error {
      color: $input-error;
    }
  }
  .error {
    font-size: 12px;
    position: absolute;
    color: $input-error;
    left: 0;
    top: calc(100% + 3px);
    white-space: nowrap;
    @include mobile {
      white-space: normal;
      text-align: left;
    }
  }
}
</style>
