import ru from './ru'
import en from './en'
import store from '../store'

const langs = {
  ru,
  en,
}

export function lang(q) {
  return langs[store.state.lang.lang][q]
}
