<template>
  <div class="wrapper">
    <HeaderMock />
    <router-view />
    <FooterMock />
    <teleport to="#cookie">
      <CookieMessage />
    </teleport>
  </div>
</template>

<script>
import CookieMessage from './components/CookieMessage.vue'
import FooterMock from './components/mock/FooterMock.vue'
import HeaderMock from './components/mock/HeaderMock.vue'

export default {
  components: { HeaderMock, FooterMock, CookieMessage },
}
</script>
