<template>
  <div :class="['sw-content', { reverse: reverse }]">
    <div
      v-if="promos[activeSlide - 1].title || promos[activeSlide - 1].subtitle"
      class="sw-content-titles"
    >
      <MoveAnimation :hide="animateSlide">
        <template #content>
          <h1
            v-if="promos[activeSlide - 1].title"
            v-html="promos[activeSlide - 1].title"
            :style="{
              ...(titlesConfig && device !== 'mobile'
                ? titlesConfig.title_size
                : titlesConfig && device === 'mobile'
                ? titlesConfig.title_size_mobile
                : ''),
            }"
          />
          <h4
            v-if="promos[activeSlide - 1].subtitle"
            v-html="promos[activeSlide - 1].subtitle"
            :style="{
              ...(titlesConfig && device !== 'mobile'
                ? titlesConfig.subtitle_size
                : titlesConfig && device === 'mobile'
                ? titlesConfig.subtitle_size_mobile
                : ''),
              maxWidth: device == 'desktop' ? '400px' : '',
            }"
          />
        </template>
      </MoveAnimation>
    </div>
    <div
      :style="{
        width: `${goldConfig.width}px`,
        ...(goldConfig.style_wrapper || {}),
      }"
      class="sw-content-gold-wrapper"
    >
      <Swiper
        :effect="'fade'"
        :speed="300"
        :modules="modules"
        :navigation="{
          nextEl: '.button-next-' + id,
          prevEl: '.button-prev-' + id,
        }"
        @swiper="onSwiperGold"
        @slideChange="onSlideGoldChange"
        class="swiper-gold"
      >
        <SwiperSlide v-for="(promo, i) of promos" :key="i">
          <div
            :class="['sw-content-gold', goldConfig.class]"
            :style="{
              width: `${goldConfig.width}px`,
              height: `${goldConfig.height}px`,
            }"
          >
            <div class="sw-content-swiper-item">
              <div>
                <h5 v-html="promo.gold.title" />
                <p v-html="promo.gold.text" />
              </div>
              <router-link
                v-if="promo.gold.link_path"
                :to="promo.gold.link_path"
                :class="linkClass"
              >
                {{ promo.gold.link_name }}
              </router-link>
              <button
                v-else
                :class="linkClass"
                @click="$emit('click-button-gold', promo.id)"
                type="button"
              >
                {{ promo.gold.link_name }}
              </button>
            </div>
          </div>
        </SwiperSlide>
        <div v-if="promos.length > 1" class="sw-content-gold-nav">
          <span class="page">{{ activeSlide }} из {{ promos.length }}</span>
          <div id="sw-progress-gold" class="swiper-pagination">
            <div class="progress" :class="{ active: activeProgress }" />
          </div>
          <ButtonNavigationSwiper :class="`button-prev-${id} left`" reverse />
          <ButtonNavigationSwiper :class="`button-next-${id}`" />
        </div>
      </Swiper>
    </div>
    <Swiper
      class="swiper-img"
      :style="{ width: `${imgConfig.width}px` }"
      @swiper="onSwiperImage"
      @slideChange="onSwiperImageSlideChange"
    >
      <SwiperSlide v-for="(promo, i) of promos" :key="`img-${i}`">
        <img
          :src="require(`@/assets/img/${promo.img}`)"
          :width="imgConfig.width"
          alt="sw-content"
          class="sw-content-img"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import 'swiper/swiper.min.css'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/navigation/navigation.scss'

import { EffectFade, Navigation } from 'swiper'
import ButtonNavigationSwiper from './ButtonNavigationSwiper.vue'
import MoveAnimation from './MoveAnimation.vue'
export default {
  name: 'SwipersContent',
  components: {
    Swiper,
    SwiperSlide,
    ButtonNavigationSwiper,
    MoveAnimation,
  },
  props: {
    promos: {
      type: Array,
      required: true,
    },
    imgConfig: {
      type: Object,
      required: true,
    },
    goldConfig: {
      type: Object,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    titlesConfig: {
      type: Object,
      required: false,
    },
    id: {
      type: Number,
    },
  },
  computed: {
    linkClass() {
      if (this.goldConfig.class == 'gold')
        return 'btn btn-dark-gradient btn-normal-2'

      if (this.goldConfig.class == 'dark') return 'btn btn-gold-link'

      return 'btn btn-gold btn-normal-2'
    },
  },
  setup(props) {
    const activeSlide = ref(1)
    const activeProgress = ref(false)
    const animateSlide = ref(false)
    const swiperImage = ref(null)
    const swiperGold = ref(null)
    const store = useStore()
    const delayAnimate = computed(() => store.state.modals.delayAnimate)
    const timeOut = ref(5000)
    const timerId = ref(0)

    const onSwiperImage = (swiper) => {
      swiperImage.value = swiper
    }
    const onSwiperGold = (swiper) => {
      swiperGold.value = swiper
    }

    const swiperImageSlideTo = (ind) => {
      swiperImage.value.slideTo(ind, 300)
    }
    const swiperGoldSlideTo = (ind) => {
      swiperGold.value.slideTo(ind, 300)
    }

    const onSlideGoldChange = (sw) => {
      animateSlide.value = true
      swiperImageSlideTo(sw.activeIndex)
      activeProgress.value = false
      clearInterval(timerId.value)
      setTimeout(() => {
        setInter()
        activeSlide.value = sw.activeIndex + 1
        animateSlide.value = false
        activeProgress.value = true
      }, delayAnimate)
    }
    const onSwiperImageSlideChange = (sw) => {
      swiperGoldSlideTo(sw.activeIndex)
    }

    const setInter = () => {
      activeProgress.value = true
      timerId.value = setInterval(() => {
        if (activeSlide.value < props.promos.length)
          swiperImage.value.slideTo(activeSlide.value, 300)
        else swiperImage.value.slideTo(0, 300)
      }, timeOut.value + delayAnimate.value)
    }
    if (props.promos.length > 1) setInter()

    return {
      onSwiperGold,
      onSwiperImage,
      onSlideGoldChange,
      onSwiperImageSlideChange,
      activeSlide,
      animateSlide,
      activeProgress,
      delayAnimate,
      modules: [EffectFade, Navigation],
    }
  },
}
</script>

<style lang="scss" scoped>
.reverse {
  .sw-content-img {
    margin-left: 0 !important;
  }
  .swiper-img {
    margin-left: 0 !important;
  }
  .sw-content-gold-wrapper {
    right: 0;
    bottom: 50%;
    transform: translateY(50%);
    @include mw(1023) {
      bottom: 0;
      transform: translateY(0);
    }
  }
}
.sw-content {
  position: relative;
  @include mw(1023) {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  @include mobile {
    margin-right: -16px;
    margin-left: -16px;
  }
  &-img {
    margin-left: auto;
  }
  &-titles {
    position: absolute;
    top: 65px;
    z-index: 2;
    @include mw(1023) {
      position: inherit;
      top: 0px;
      margin-bottom: 40px;
      order: 1;
    }
    @include mobile {
      margin-bottom: 24px;
      padding: 0 16px;
    }
    h1 {
      font-weight: 800;
      font-size: 64px;
      line-height: 78px;
      color: $gold;
      margin-bottom: 16px;
      @include mobile {
        font-size: 32px;
        line-height: 39px;
        margin-bottom: 8px;
      }
    }
    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: $black-main;
      max-width: 440px;
      @include mw(1023) {
        max-width: 100%;
      }
      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  &-gold-wrapper {
    position: absolute;
    bottom: -90px;
    z-index: 1;
    @include mw(1023) {
      width: 100% !important;
      position: relative;
      bottom: 0 !important;
      order: 3;
    }
  }
  &-gold {
    padding: 24px 40px 79px 40px;
    @include mw(1023) {
      width: 100% !important;
      height: 278px !important;
    }
    @include mobile {
      padding: 16px 16px 79px 16px;
      height: 254px !important;
    }
    &.gold {
      background: $gold-gradient;
      h5 {
        color: $black;
      }
      p {
        color: $black;
      }
    }
    &.dark {
      background: $dark-gradient;
      h5 {
        color: $white;
      }
      p {
        color: $white;
      }
    }
    &.dark-gold {
      background: $dark-gradient;
      h5 {
        color: $white;
      }
      p {
        color: $white;
      }
    }
    &-nav {
      position: absolute;
      bottom: 16px;
      z-index: 1;
      display: flex;
      left: 40px;
      align-items: center;
      width: calc(100% - 56px);
      @include mobile {
        left: 16px;
        width: calc(100% - 32px);
      }
      .line {
        max-width: 290px;
        width: 100%;
        height: 1px;
        background: $milk-line;
        margin: 0 10px;
      }
      .page {
        font-size: 14px;
        line-height: 140%;
        color: $milk-bg;
        opacity: 0.5;
        white-space: nowrap;
      }
    }
  }
  &-swiper-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 8px;
      @include mobile {
        font-size: 18px;
        line-height: 22px;
      }
    }
    p {
      font-size: 16px;
      line-height: 140%;
      @include mobile {
        font-size: 12px;
        line-height: 140%;
      }
    }
  }
}
.swiper-img {
  margin-left: auto;
  z-index: 0;
  pointer-events: none;
  @include mw(1023) {
    // width: calc(100% + 20px);
    // margin: 0 -10px;
    width: 100% !important;
    display: grid;
    grid-template-columns: 1fr;
    order: 2;
    img {
      width: 100% !important;
    }
  }
}
.swiper-gold {
  width: 100%;
  height: 100%;
  .swiper-button-disabled {
    opacity: 0.5 !important;
    cursor: auto;
  }

  #sw-progress-gold {
    width: 100%;
    position: relative !important;
    margin-left: 10px;
    background: rgb(247, 244, 237, 0.4);
    border-radius: 10px;
    height: 4px;
    .swiper-pagination-progressbar-fill {
      background: $milk-bg !important;
      border-radius: 10px !important;
    }
    .progress {
      width: 0;
      position: absolute;
      top: 0;
      left: 0;
      background: $milk-bg;
      height: 4px;
      border-radius: 10px;
      &.active {
        animation: progress 5.3s linear infinite;
      }
    }
  }
}
.left {
  // transform: rotate(180deg);
  margin: 0 10px;
}
.swiper-button-disabled {
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
}
@keyframes progress {
  91% {
    width: 100%;
  }
}
</style>
