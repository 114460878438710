import '@/styles/index.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixinDevice from './mixins/device'
import mixinMethods from './mixins/methods'
import VueScrollTo from 'vue-scrollto'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
  .use(store)
  .use(router)
  .mixin(mixinDevice)
  .mixin(mixinMethods)
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: { autoHideBadge: true },
  })
  .use(VueScrollTo)
  .mount('#app')
