<template>
  <div class="rel">
    <button type="button" @click="onClick" :class="{ active: isShowModal }">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_5823_36642)">
          <path
            d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.33317 8.00016C5.33317 11.6821 6.52708 14.6668 7.99984 14.6668C9.4726 14.6668 10.6665 11.6821 10.6665 8.00016C10.6665 4.31826 9.4726 1.3335 7.99984 1.3335C6.52708 1.3335 5.33317 4.31826 5.33317 8.00016Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.3335 8H14.6668"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5823_36642">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>{{ lang === 'ru' ? 'Рус' : 'Eng' }}</span>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 1L5 5L1 1" stroke="white" stroke-linecap="round" />
      </svg>
    </button>
    <ModalLanguages v-if="isShowModal" @close="close" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ModalLanguages from './ModalLanguages.vue'
export default {
  name: 'ButtonLanguage',
  components: { ModalLanguages },
  computed: {
    ...mapState({
      isShowModal: (state) => state.modals.mockLanguagesModal,
      lang: (state) => state.lang.lang,
    }),
  },
  methods: {
    ...mapMutations({
      setModalProps: 'modals/setModalProps',
    }),
    onClick() {
      this.setModalProps({
        name: 'mockLanguagesModal',
        value: !this.isShowModal,
      })
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.setModalProps({
          name: 'mockLanguagesModal',
          value: false,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rel {
  position: relative;
  margin-right: 24px;
  button {
    font-size: 14px;
    line-height: 17px;
    color: $white;
    display: flex;
    align-items: center;
    svg {
      transition: all 0.3s;
    }
    &.active {
      svg:last-child {
        transform: rotate(180deg);
      }
    }
    span {
      margin: 0px 5px;
    }
  }
}
</style>
