<template>
  <main id="main" class="main milk-bg">
    <div class="document">
      <div class="container">
        <div class="path">
          <router-link to="/">{{ lang('q37') }}</router-link
          >&emsp;/&emsp;<span v-html="lang('q38')" />
        </div>
        <div class="text white-bg">
          <h1 v-html="lang('q38')" />
          <p v-html="lang('q39')" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { lang } from '@/lang'
export default {
  name: 'DocumentCookie',
  data() {
    return {
      lang,
    }
  },
}
</script>

<style lang="scss">
.document {
  .container {
    max-width: 840px;
  }
  .path {
    width: fit-content;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $dark;
    margin-top: 40px;
    margin-bottom: 40px;
    @include mobile {
      display: none;
    }
  }
  .text {
    border-radius: 16px;
    padding: 40px 110px 64px 110px;
    margin-bottom: 80px;
    @include tablet {
      padding: 40px 90px 64px 90px;
    }
    @include mobile {
      margin-top: 24px;
      padding: 40px 16px 64px 16px;
      margin-bottom: 56px;
    }
    h1 {
      font-weight: 800;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      margin-bottom: 40px;
      @include mobile {
        font-size: 24px;
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: $main-grey;
    }
  }
}
</style>
