import { createStore } from 'vuex'
import modals from './modals'
import lang from './lang'

export default createStore({
  modules: {
    modals,
    lang,
  },
})
