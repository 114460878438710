<template>
  <button
    @click="onClick"
    type="button"
    :class="['btn btn-menu', { open: vMenuMobile }]"
  >
    <div class="line" />
    <div v-show="!vMenuMobile" class="line" />
    <div class="line" />
  </button>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ButtonMenu',
  computed: {
    ...mapState({
      vMenuMobile: (state) => state.modals.menuMobile,
    }),
  },
  methods: {
    ...mapMutations({
      setModalProps: 'modals/setModalProps',
    }),
    onClick() {
      this.setModalProps({
        name: 'menuMobile',
        value: !this.vMenuMobile,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-menu {
  width: 24px;
  height: 18px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  &.open {
    width: 24px;
    height: 24px;
    & .line:first-child {
      transform: rotate(-45deg) translate3d(-11px, 5px, 0);
    }
    & .line:last-child {
      width: 24px;
      transform: rotate(45deg) translate3d(-10px, -5px, 0);
    }
  }
  .line {
    width: 24px;
    height: 2px;
    background: $gold;
    border-radius: 10px;
    transition: all 0.3s;
  }
  & .line:last-child {
    width: 20px;
  }
}
</style>
