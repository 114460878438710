<template>
  <div class="menu flex-col" :class="{ open }">
    <a
      class="social-link"
      @click="setModalProps({ name: 'mockFeedbackModal', value: true })"
      >{{ lang('q2') }}</a
    >
    <div class="lang flex-c-c">
      <a :href="toRussian" type="button">Рус</a>
      <button @click="onClickLangButton()" type="button">Eng</button>
    </div>
    <div class="icons flex-c-c">
      <a
        target="_blank"
        href="https://vk.com/goldnapotheka"
        class="social-icon"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.24533 2.24533C0 4.49067 0 8.1056 0 15.3333V16.6667C0 23.8933 0 27.5093 2.24533 29.7547C4.49066 32 8.10559 32 15.3333 32H15.3333H16.6667H16.6667C23.8933 32 27.5093 32 29.7547 29.7547C32 27.5093 32 23.8944 32 16.6667V16.6667V15.3333V15.3333C32 8.10666 32 4.49066 29.7547 2.24533C27.5093 0 23.8944 0 16.6667 0H15.3333C8.10667 0 4.49067 0 2.24533 2.24533ZM6.33385 10H8.66665C9.26292 10 9.48692 10.2613 9.71092 10.9077C10.8523 14.2315 12.7765 17.1371 13.5669 17.1371C13.8645 17.1371 14 17.0005 14 16.2464V12.8107C13.9466 11.8326 13.6049 11.4095 13.3518 11.0962C13.1955 10.9027 13.0731 10.7511 13.0731 10.5365C13.0731 10.2752 13.296 10 13.6661 10H17.3333C17.8283 10 18 10.2645 18 10.8576V15.4805C18 15.9744 18.2144 16.1483 18.3627 16.1483C18.6592 16.1483 18.9067 15.9744 19.4507 15.4304C21.1317 13.552 22.3179 10.6603 22.3179 10.6603C22.4661 10.3136 22.7403 10 23.3333 10H25.6661C26.3723 10 26.5216 10.3637 26.3723 10.8576C26.0757 12.2176 23.2331 16.2219 23.2331 16.2219C22.9856 16.6176 22.8864 16.8149 23.2331 17.2597C23.3554 17.4311 23.6164 17.6869 23.9148 17.9794C24.22 18.2786 24.5644 18.6161 24.8395 18.9408C25.8421 20.0683 26.5973 21.0197 26.8075 21.6747C27.0005 22.3328 26.6667 22.6667 26 22.6667H23.6661C23.0413 22.6667 22.7269 22.3167 22.0471 21.56C21.759 21.2393 21.4052 20.8456 20.9344 20.3744C19.5488 19.04 18.9568 18.8672 18.6101 18.8672C18.1408 18.8672 18 19.0005 18 19.6672V21.76C18 22.3328 17.8165 22.6667 16.3328 22.6667C13.8613 22.6667 11.1445 21.1659 9.21705 18.3968C6.32425 14.3435 5.53385 11.2779 5.53385 10.6603C5.53385 10.3136 5.66719 10 6.33385 10Z"
            fill="#D1B36C"
          />
        </svg>
      </a>
      <a class="social-icon" href="https://t.me/AsGoodAsGoldn">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.24561 2.24533C0.000274658 4.49067 0.000274658 8.1056 0.000274658 15.3333V16.6667C0.000274658 23.8933 0.000274658 27.5093 2.24561 29.7547C4.49094 32 8.10586 32 15.3336 32H15.3336H16.6669H16.667C23.8936 32 27.5096 32 29.7549 29.7547C32.0003 27.5093 32.0003 23.8944 32.0003 16.6667V16.6667V15.3333V15.3333C32.0003 8.10666 32.0003 4.49066 29.7549 2.24533C27.5096 0 23.8947 0 16.6669 0H15.3336C8.10694 0 4.49094 0 2.24561 2.24533ZM16.5734 11.8119C15.0171 12.4592 11.9068 13.7989 7.24252 15.8311C6.4851 16.1323 6.08834 16.427 6.05222 16.7151C5.99117 17.202 6.60094 17.3937 7.43129 17.6548C7.54424 17.6903 7.66127 17.7271 7.78124 17.7661C8.59818 18.0317 9.69709 18.3424 10.2684 18.3547C10.7866 18.3659 11.365 18.1523 12.0035 17.7138C16.3615 14.772 18.6112 13.2851 18.7524 13.253C18.852 13.2304 18.9901 13.202 19.0837 13.2851C19.1772 13.3683 19.168 13.5258 19.1581 13.568C19.0977 13.8255 16.7041 16.0508 15.4655 17.2024C15.0793 17.5614 14.8054 17.816 14.7494 17.8742C14.624 18.0045 14.4962 18.1277 14.3733 18.2461C13.6143 18.9778 13.0452 19.5264 14.4048 20.4224C15.0582 20.8529 15.581 21.209 16.1026 21.5642C16.6722 21.9521 17.2404 22.339 17.9755 22.8209C18.1628 22.9437 18.3416 23.0712 18.5158 23.1954C19.1788 23.668 19.7743 24.0926 20.5101 24.0248C20.9377 23.9855 21.3793 23.5835 21.6036 22.3845C22.1336 19.5509 23.1755 13.4113 23.4163 10.8813C23.4374 10.6596 23.4108 10.376 23.3895 10.2514C23.3682 10.1269 23.3237 9.94948 23.1618 9.81813C22.9701 9.66258 22.6742 9.62978 22.5418 9.63211C21.94 9.64271 21.0167 9.96376 16.5734 11.8119Z"
            fill="#D1B36C"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { lang } from '@/lang'
export default {
  name: 'MenuMobileMock',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lang,
    }
  },
  computed: {
    toRussian() {
      return process.env.VUE_APP_ROUTE_TO_RUSSIAN
    },
  },
  methods: {
    ...mapMutations({
      setModalProps: 'modals/setModalProps',
    }),
    onClickLangButton() {
      this.setModalProps({
        name: 'menuMobile',
        value: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  align-items: center;
  position: fixed;
  left: 0px;
  top: 47px;
  padding: 24px 0;
  width: 100%;
  background: $main-grey;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  z-index: 100;
  visibility: hidden;
  transform: translateY(-120%);
  transition: all 0.5s;
  &.open {
    transform: translateY(0);
    visibility: visible;
  }
  .social-link {
    margin-bottom: 24px;
    display: inline-block;
    color: $white;
    font-size: 16px;
    line-height: 20px;
  }
  .icons {
    .social-icon:first-child {
      margin-right: 8px;
    }
  }
  .lang {
    margin-bottom: 24px;
    button,
    a {
      font-size: 14px;
      line-height: 17px;
      color: $white;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
</style>
