<template>
  <div v-if="!cookieMessage" class="cookie">
    <div class="container">
      <div class="cookie-wrapper">
        <p v-html="lang('q35')" />
        <button
          @click="setCookieMessage"
          type="button"
          class="btn btn-gold btn-normal"
        >
          {{ lang('q36') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { lang } from '@/lang'
export default {
  name: 'CookieMessage',
  data() {
    return {
      lang,
    }
  },
  computed: {
    ...mapState({
      cookieMessage: (state) => state.modals.cookieMessage,
    }),
  },
  methods: {
    ...mapMutations({
      setCookieMessage: 'modals/setCookieMessage',
    }),
  },
}
</script>

<style lang="scss" scoped>
.cookie {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  padding: 8px 0;
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    margin-right: 24px;
    font-size: 16px;
    line-height: 140%;
    color: $black;
    @include mobile {
      font-size: 12px;
    }
    a {
      text-decoration: underline;
    }
  }
}
</style>
