import Cookies from 'js-cookie'

const state = () => ({
  lang: 'en',
})

const mutations = {
  setLanguage(state, payload) {
    state.lang = payload
    Cookies.set('siteLanguage', payload)
  },
}

export default {
  state,
  mutations,
  namespaced: true,
}
