<template>
  <main id="main" class="main milk-bg">
    <div class="document">
      <div class="container">
        <div class="path">
          <router-link to="/">Главная</router-link>&emsp;/&emsp;Отказ от
          ответственности
        </div>
        <div class="text white-bg">
          <h1>Отказ от ответственности</h1>
          <p>
            Gold'n Apotheka не осуществляет медицинскую деятельность и не
            оказывает Пользователям Сайта медицинские услуги, в том числе
            направленные на профилактику, диагностику и лечение заболеваний. Вся
            информация размещенная на Сайте, в том числе при описании пищевых и
            биологически активных добавок, носит только информационный характер
            и не может заменить консультации специалистов. При этом информация
            размещенная на Сайте не является исчерпывающей в отношении состояния
            здоровья Пользователя, и не указывает на диагноз Пользователя и
            способ его лечения. В связи с тем, что реакции организма на пищевые
            и биологически активные добавки могут различаться у каждого
            человека, в том числе в зависимости от личной непереносимости
            компонентов и/или аллергических реакций, при приеме любых пищевых и
            биологически активных добавок настоятельно рекомендуем
            проконсультироваться с вашим лечащим врачом для наиболее эффективных
            результатов и в целях избежания возможного ухудшения здоровья. В
            случае возникновения вопросов относительно предложенных на Сайте
            рекомендаций Пользователю необходимо проконсультироваться с лечащим
            врачом и связаться с Gold'n Apotheka.
            <br /><br />
            Gold'n Apotheka не рекомендует при выборе и приеме пищевых и
            биологически активных добавок Пользователям осуществлять
            самодиагностику состояния здоровья, определять заболевания и способы
            их лечения самостоятельно. При заполнении Пользователем опроса на
            Сайте, Gold'n Apotheka исходит из предоставления Пользователем
            достоверных сведений, подтвержденных лечащим врачом. Gold'n Apotheka
            не несет ответственности за любую информацию, предоставленную на
            Сайте. Рекомендации по приему пищевых и биологически активных
            добавок не подлежат использованию для любых медицинских целей, в том
            числе лечения заболеваний.
            <br /><br />
            При наличии расхождений о приеме пищевых и биологически активных
            добавок на упаковке и Сайте, Пользователю необходимо связаться с
            Gold'n Apotheka либо исходить из информации, размещенной на
            упаковке.
            <br /><br />
            Gold'n Apotheka не несет ответственности за любые убытки при
            использовании Сайта, его функционала и рекомендуемых пищевых и
            биологически активных добавок, применения их для лечения
            заболеваний, осуществления медицинской диагностики и других
            медицинских целей.
            <br /><br />
            Все отзывы и комментарии Пользователей, размещенные на Сайте
            являются их личной точкой зрения, основанной на их индивидуальном
            состоянии здоровья.
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'DenialOfResponsibility',
}
</script>

<style lang="scss">
.document {
  .container {
    max-width: 840px;
  }
  .path {
    width: fit-content;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $dark;
    margin-top: 40px;
    margin-bottom: 40px;
    @include mobile {
      display: none;
    }
  }
  .text {
    border-radius: 16px;
    padding: 40px 110px 64px 110px;
    margin-bottom: 80px;
    @include tablet {
      padding: 40px 90px 64px 90px;
    }
    @include mobile {
      margin-top: 24px;
      padding: 40px 16px 64px 16px;
      margin-bottom: 56px;
    }
    h1 {
      font-weight: 800;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      margin-bottom: 40px;
      @include mobile {
        font-size: 24px;
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: $main-grey;
    }
  }
}
</style>
