<template>
  <router-link to="/">
    <svg
      width="114"
      height="117"
      viewBox="0 0 114 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.5 6.49361L63.1 6.69341C65.2 6.89322 66.7 5.8942 66.9 3.99607C67.1 2.09794 65.9 0.699312 63.8 0.499508L60.3 0L59.3 8.69145L61.2 8.89125L61.5 6.49361ZM62 1.79823L63.5 1.99803C64.6 2.09794 65.1 2.69735 65 3.69636C64.9 4.49558 64.4 4.99508 63.5 4.99508C63.4 4.99508 63.3 4.99508 63.2 4.99508L61.7 4.79528L62 1.79823Z"
        fill="#D1B36C"
      />
      <path
        d="M101.5 80.6207L106.1 83.1182L99.5 84.1172L98.8 85.5158L106.4 89.6118L107.3 88.0134L102.7 85.5158L109.2 84.5168L110 83.1182L102.3 79.0222L101.5 80.6207Z"
        fill="#D1B36C"
      />
      <path
        d="M56.9 86.6148C63.9 86.6148 70.4 83.9174 75.2 78.9223C84.8 69.132 84.6 52.4484 74.7 42.658L65.7 33.6669C65.5 33.4671 65.2 33.2673 64.8 33.2673C64.5 33.2673 64.2 33.3672 63.9 33.6669L46.9 50.6502C41.5 56.1447 41 64.7363 45.9 70.4307C48.6 73.5276 52.5 75.3259 56.7 75.3259C60.8 75.3259 64.8 73.5276 67.5 70.4307C72.4 64.8362 71.9 56.1447 66.5 50.6502L65.5 49.6511C65.2 49.3514 64.9 49.2515 64.5 49.2515C64.1 49.2515 63.8 49.3514 63.5 49.6511L55 58.1428C54.4 58.7422 54.4 59.8411 55 60.4405L57.3 62.7383C57.6 63.038 58 63.2378 58.4 63.2378C58.8 63.2378 59.2 63.038 59.5 62.7383L63.9 58.3426C65 61.2397 64.3 64.4366 62.1 66.6344C60.6 68.1329 58.7 68.9322 56.6 68.9322C54.2 68.9322 51.9 67.8332 50.4 65.8352C48 62.7383 48.4 58.2427 51.3 55.3455L64.7 41.9587L69.9 47.1536C77.5 54.7461 77.7 67.034 70.4 74.5266C66.8 78.223 61.9 80.3209 56.7 80.3209C51.5 80.3209 46.6 78.3229 43 74.5266C35.7 67.034 35.9 54.7461 43.5 47.1536L60.5 30.1703C61 29.6708 61 28.9715 60.5 28.472L57.6 25.5748C57.4 25.375 57.1 25.1752 56.8 25.1752C56.5 25.1752 56.2 25.2751 56 25.5748L39.2 42.4582C29.3 52.3485 28.8 68.5325 38.2 78.5227C43.1 83.7176 49.7 86.6148 56.9 86.6148Z"
        fill="#D1B36C"
      />
      <path
        d="M72.5 11.2889C74.9 12.0881 77.3 10.8893 78.1 8.39174C78.9 5.8942 77.7 3.49656 75.3 2.79725C74.8 2.59744 74.3 2.59744 73.9 2.59744C72 2.59744 70.4 3.79626 69.8 5.6944C68.9 8.09204 70.1 10.4897 72.5 11.2889ZM71.5 6.1939C72 4.59548 73.4 3.89617 74.8 4.29577C76.2 4.69538 76.8 6.1939 76.3 7.69243C75.9 8.99115 74.9 9.69046 73.8 9.69046C73.5 9.69046 73.3 9.69046 73 9.59056C71.7 9.19095 71 7.79233 71.5 6.1939Z"
        fill="#D1B36C"
      />
      <path
        d="M94.3 91.2102L98.6 99.602L99.8 98.1034L98.8 96.3052L101.2 93.4081L103.1 94.1074L104.4 92.6088L95.4 89.7117L94.3 91.2102ZM99.8 93.0085L98.2 95.0065L96.5 91.9095L99.8 93.0085Z"
        fill="#D1B36C"
      />
      <path
        d="M48.9 9.19095L50.7 8.99115L50.6 6.99312L54.3 6.69341L54.5 8.79135L56.4 8.59154L56 3.89617C55.8 1.39862 54.4 0.0999017 52.4 0.0999017C52.3 0.0999017 52.1 0.0999017 52 0.0999017C49.8 0.299705 48.5 1.89813 48.7 4.49558L48.9 9.19095ZM52 1.79823C53.1 1.69833 53.9 2.29774 54 3.79626L54.1 4.99508L50.4 5.29479L50.3 4.09597C50.2 2.69735 50.9 1.89813 52 1.79823Z"
        fill="#D1B36C"
      />
      <path
        d="M14.7 58.5424C14.7 81.9194 33.7 100.901 57 100.901C80.3 100.901 99.3 81.9194 99.3 58.5424C99.3 35.1654 80.3 16.1841 57 16.1841C33.7 16.1841 14.7 35.2653 14.7 58.5424ZM57 18.8814C78.9 18.8814 96.7 36.6639 96.7 58.6423C96.7 80.6207 78.9 98.3032 57 98.3032C35.1 98.3032 17.3 80.5208 17.3 58.5424C17.3 36.564 35.1 18.8814 57 18.8814Z"
        fill="#D1B36C"
      />
      <path
        d="M111.7 56.2446C110.4 56.2446 109.4 57.2437 109.4 58.5424C109.4 59.8411 110.4 60.8401 111.7 60.8401C113 60.8401 114 59.8411 114 58.5424C114 57.3436 113 56.2446 111.7 56.2446Z"
        fill="#D1B36C"
      />
      <path
        d="M108.4 39.4612L103.8 40.8598L104.3 42.658L106.3 42.0586L107.4 45.5552L105.4 46.1546L105.9 47.9528L110.5 46.5542C113 45.755 114 43.9567 113.4 41.8588C112.9 40.2604 111.7 39.2614 110 39.2614C109.5 39.1615 109 39.2614 108.4 39.4612ZM111.7 42.2584C112 43.3573 111.5 44.2564 110.1 44.6561L108.9 45.0557L107.8 41.5591L109 41.1595C110.5 40.7599 111.4 41.2594 111.7 42.2584Z"
        fill="#D1B36C"
      />
      <path
        d="M36.2 13.187L34.2 8.39174L39.9 11.6885L41.3 11.0891L38 3.09695L36.3 3.79626L38.3 8.69145L32.6 5.29479L31.2 5.8942L34.5 13.8863L36.2 13.187Z"
        fill="#D1B36C"
      />
      <path
        d="M100.4 33.7668L102.3 32.6679L103.9 33.0675L102.1 36.9636L103.1 38.8618L105.7 33.4671L110.7 34.566L109.7 32.7677L104.4 31.5689L108 29.5709L107.1 27.9725L99.5 32.0684L100.4 33.7668Z"
        fill="#D1B36C"
      />
      <path
        d="M105.5 69.5316L105 71.3298L108 74.5266L103.7 75.4258L103.1 77.3239L109.3 76.0252L112.3 76.9243L112.9 75.1261L109.9 74.227L105.5 69.5316Z"
        fill="#D1B36C"
      />
      <path
        d="M97.9 29.7707L99.2 28.7717L96.4 25.375L98 24.0763L100.3 26.9735L101.5 25.9744L99.2 23.0773L100.7 21.8785L103.3 25.1752L104.6 24.1762L100.7 19.3809L94 24.8755L97.9 29.7707Z"
        fill="#D1B36C"
      />
      <path
        d="M82.1 15.3849L85.5 9.19095L87.7 10.4897L88.5 8.99115L82.4 5.59449L81.6 7.09302L83.9 8.29184L80.4 14.4857L82.1 15.3849Z"
        fill="#D1B36C"
      />
      <path
        d="M87.6 19.0812L89.9 16.284L92.7 18.6816L90.4 21.379L91.8 22.5778L97.4 15.8844L95.9 14.6855L93.8 17.3829L90.9 14.9853L93.1 12.3878L91.7 11.189L86.1 17.8824L87.6 19.0812Z"
        fill="#D1B36C"
      />
      <path
        d="M7.2 36.8637C9.5 38.0625 12 37.3632 13.2 35.0655C14.4 32.8676 13.6 30.2702 11.3 29.0714C10.6 28.6718 9.8 28.472 9.1 28.472C7.5 28.472 6.1 29.2712 5.3 30.7697C4.1 33.1674 4.9 35.6649 7.2 36.8637ZM6.8 31.6688C7.5 30.3701 9 29.9705 10.4 30.7697C11.8 31.5689 12.3 33.0675 11.7 34.2663C11.3 35.0655 10.5 35.565 9.5 35.565C9 35.565 8.5 35.4651 8 35.1654C6.6 34.4661 6.1 32.9676 6.8 31.6688Z"
        fill="#D1B36C"
      />
      <path
        d="M37.5234 104.127L34.2279 112.171L35.9863 112.89L39.2819 104.846L37.5234 104.127Z"
        fill="#D1B36C"
      />
      <path
        d="M2.3 60.94C3.6 60.94 4.6 59.941 4.6 58.6423C4.6 57.3436 3.6 56.3445 2.3 56.3445C1 56.3445 0 57.3436 0 58.5424C0 59.8411 1 60.94 2.3 60.94Z"
        fill="#D1B36C"
      />
      <path
        d="M70.1 113.788L69.6 111.79L73.2 110.891L72.9 109.392L69.2 110.291L68.8 108.493L72.9 107.394L72.5 105.796L66.6 107.294L68.7 115.786L74.8 114.188L74.4 112.689L70.1 113.788Z"
        fill="#D1B36C"
      />
      <path
        d="M27.1 10.19L27.7 12.0881L28.7 11.4887L28.3 9.59056C28.1 8.99115 28 8.69145 27.9 8.49164C27.7 8.09204 27.3 7.89223 26.9 7.89223C26.7 7.89223 26.5 7.99213 26.3 8.09204C25.8 8.39174 25.6 9.09105 26 9.69046C26.2 10.0901 26.6 10.2899 27.1 10.19Z"
        fill="#D1B36C"
      />
      <path
        d="M62.4 112.19L62.6 114.687C62.1 114.987 61.7 115.087 61.2 115.187C59.7 115.287 58.5 114.188 58.4 112.589C58.3 110.891 59.3 109.792 60.9 109.692C61.7 109.592 62.4 109.892 63 110.591L64.1 109.292C63.3 108.393 62.3 107.994 61 107.994C60.9 107.994 60.8 107.994 60.7 107.994C58.1 108.194 56.4 110.192 56.6 112.789C56.8 115.386 58.7 117.185 61.3 116.985C62.4 116.885 63.6 116.485 64.5 115.686L64.3 112.19H62.4Z"
        fill="#D1B36C"
      />
      <path
        d="M3.9 47.3534C6.4 48.0527 8.7 46.8539 9.4 44.3563C9.7 43.2574 9.7 41.9587 9.2 40.9597L5.8 39.9607L5.3 41.5591L7.7 42.2584C7.8 42.7579 7.8 43.2574 7.7 43.7569C7.4 45.2555 6 45.9548 4.4 45.5552C2.8 45.0557 2 43.7569 2.5 42.2584C2.7 41.4592 3.2 40.8598 4 40.5601L3.2 38.9617C2 39.4612 1.2 40.4602 0.8 41.7589C0.1 44.2564 1.3 46.6541 3.9 47.3534Z"
        fill="#D1B36C"
      />
      <path
        d="M46.2 112.389L43.5 106.295L42 105.996L40 114.487L41.8 114.887L43 109.692L45.7 115.786L47.2 116.186L49.2 107.694L47.4 107.294L46.2 112.389Z"
        fill="#D1B36C"
      />
      <path
        d="M89.5 96.2053L90.4 102.299L84.7 99.8018L83.5 100.801L88.8 107.694L90.2 106.595L87.1 102.599L91.5 104.397L92.1 103.898L91.4 99.1025L94.6 103.198L96 102.199L90.7 95.3062L89.5 96.2053Z"
        fill="#D1B36C"
      />
      <path
        d="M24.8 20.4798C26.8 18.7815 27.1 16.284 25.4 14.2859C24.5 13.187 23.3 12.6875 22.2 12.6875C21.2 12.6875 20.2 13.0871 19.3 13.8863L16.5 16.284L22.1 22.9774L24.8 20.4798ZM20.2 15.1851C21.4 14.186 22.9 14.2859 24 15.5847C25.1 16.8834 24.9 18.282 23.7 19.3809L22.4 20.4798L18.9 16.3839L20.2 15.1851Z"
        fill="#D1B36C"
      />
      <path
        d="M20.5 24.4759L19.2 23.4769L16.7 26.5738L11.2 22.0783L10.1 23.5768L16.8 29.0714L20.5 24.4759Z"
        fill="#D1B36C"
      />
      <path
        d="M82.7 104.797C82.1 103.598 81.2 102.999 80 102.999C79.4 102.999 78.8 103.099 78.2 103.398L75 104.897L78.7 112.789L80.4 111.99L79.4 109.792L80.9 109.093H81L83.4 110.591L85.2 109.792L82.4 107.994C83.1 107.095 83.2 105.996 82.7 104.797ZM80.1 107.694L78.7 108.293L77.4 105.496L78.8 104.897C79.8 104.397 80.6 104.697 81 105.496C81.4 106.495 81.1 107.195 80.1 107.694Z"
        fill="#D1B36C"
      />
      <path
        d="M27.4 104.697L27.1 98.1034L25.9 97.2043L20.9 104.297L22.4 105.396L25.4 101.1L25.7 107.694L26.9 108.593L31.9 101.4L30.4 100.401L27.4 104.697Z"
        fill="#D1B36C"
      />
      <path
        d="M14.4 83.4179C14.1 83.0183 13.8 82.6187 13.4 82.4189C11.9 81.32 9.9 81.32 8.2 82.5188C6 83.9174 5.6 86.6148 7 88.6128C8.5 90.7107 11.1 91.1103 13.2 89.6118C15.4 88.1133 15.8 85.5158 14.4 83.4179ZM12.2 88.1133C11.1 88.9125 9.9 88.9125 9 88.2132C8.8 88.0134 8.6 87.9135 8.4 87.6138C7.6 86.4149 7.9 84.9164 9.2 83.9174C10.5 82.9184 12.1 83.2181 12.9 84.317C13.8 85.6157 13.5 87.1143 12.2 88.1133Z"
        fill="#D1B36C"
      />
      <path
        d="M7.4 76.5247C7.7 76.7245 8.1 76.6246 8.4 76.5247C9.9 76.1251 10.5 75.226 10 73.7274L8.9 70.4307L0.7 73.2279L1.8 76.5247C2.5 78.5227 3.5 79.6216 5.9 78.8224C7.1 78.4228 7.5 77.6236 7.4 76.5247ZM4.9 77.224C4.2 77.4238 3.6 77.224 3.2 76.3249C3.2 76.225 3.1 76.1251 3.1 76.0252L2.6 74.6265L5.2 73.7274L5.7 75.1261C6.1 76.225 5.8 76.9243 4.9 77.224ZM8.2 72.7284L8.6 73.9272C8.8 74.4268 8.4 74.8264 8.1 74.9263C7.8 75.0262 7.2 75.1261 7 74.3269L6.6 73.2279L8.2 72.7284Z"
        fill="#D1B36C"
      />
      <path
        d="M21.8 93.9076C21.6 93.4081 21.3 92.8087 20.8 92.3092L18.4 89.8116L12.1 95.8057L13.4 97.1044L15.1 95.4061L16.2 96.6049L16.3 96.7048L15.6 99.5021L17 100.901L17.8 97.7038C18.9 98.1034 20 97.9036 20.9 97.0045C21.9 96.1054 22.2 95.0065 21.8 93.9076ZM19.7 95.7058C19 96.4051 18.2 96.3052 17.4 95.506L16.3 94.4071L18.5 92.3092L19.6 93.4081C20.4 94.2073 20.4 95.0065 19.7 95.7058Z"
        fill="#D1B36C"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  name: 'LogoFooterMock',
}
</script>

<style lang="scss" scoped>
svg {
  width: 114px;
  height: 117px;
  margin: 0 10px;
  display: block;
  @include tablet {
    margin: 0;
    width: 100px;
    height: 113px;
  }
  @include mobile {
    width: 74px;
    height: 76px;
  }
}
</style>
