<template>
  <div>
    <slot name="content" />
  </div>
</template>

<script>
export default {
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  name: 'MoveAnimation',
  data() {
    return {
      h1: null,
      h4: null,
    }
  },
  // mounted() {
  //   this.h1 = this.$el.getElementsByTagName('h1')[0]
  //   this.h4 = this.$el.getElementsByTagName('h4')[0]
  //   if (this.h1 && this.h4) {
  //     this.h1.classList.toggle('vision-move')
  //     this.h4.classList.toggle('vision-move')
  //   }
  // },
  // watch: {
  //   hide() {
  //     this.animate()
  //   },
  // },
  // methods: {
  //   animate() {
  //     if (this.h1 && this.h4) {
  //       if (this.hide) {
  //         this.h1.classList.toggle('hide')
  //         this.h4.classList.toggle('hide')
  //       } else {
  //         this.h1.classList.toggle('hide')
  //         setTimeout(() => {
  //           this.h4.classList.toggle('hide')
  //         }, 200)
  //       }
  //     }
  //   },
  // },
}
</script>

<style lang="scss">
.vision-move {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.3s;
  // &.hide {
  //   opacity: 0;
  //   transform: translateX(100px);
  // }
}
</style>
