<template>
  <div
    class="modal flex-col flex-c-c"
    :class="{ default: defaultSize, open: classOpen }"
  >
    <div @click="onClose" class="bg"></div>
    <div class="modal-box" :class="classBox">
      <button @click="onClose" type="button" class="btn-close">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3334 1.66699L1.66669 12.3337M12.3334 12.3337L1.66669 1.66699L12.3334 12.3337Z"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <slot name="modal-content" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  props: {
    defaultSize: {
      type: Boolean,
    },
    classBox: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
  },
  name: 'Modal',
  mounted() {
    setTimeout(() => {
      this.setClassOpen()
    }, 300)
    document.documentElement.style.overflow = 'hidden'
    document.addEventListener('keydown', this.keyDown)
  },
  unmounted() {
    document.documentElement.style.overflow = 'auto'
    document.removeEventListener('keydown', this.keyDown)
  },
  computed: {
    ...mapState({
      classOpen: (state) => state.modals.classOpen,
    }),
  },
  methods: {
    ...mapMutations({
      setModalProps: 'modals/setModalProps',
      setClassOpen: 'modals/setClassOpen',
      onCloseModal: 'modals/onCloseModal',
    }),
    onClose() {
      this.onCloseModal({ name: this.name, payload: false })
    },
    keyDown(e) {
      if (e.key === 'Escape') this.onClose()
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1001;
  @include tablet {
    padding: 0px 16px;
  }
  @include mobile {
    padding: 0px;
    justify-content: flex-end !important;
  }
  &.open {
    .bg {
      opacity: 0.6;
    }
    .modal-box {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .bg {
    background: #2e3339;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    transition: all 0.3s;
  }
  &-box {
    border-radius: 2px;
    background: $white;
    position: relative;
    transform: translateY(150%);
    transition: all 0.3s;
    max-height: 90vh;
    overflow-y: scroll;
    opacity: 0;
    &::-webkit-scrollbar {
      display: none;
    }
    @include mobile {
      max-height: 100vh;
      overflow-y: scroll;
    }
  }
  .btn-close {
    position: absolute;
    top: 18px;
    right: 18px;
    svg path {
      transition: all 0.3s;
    }
    &:hover svg path {
      stroke: $close-hover;
    }
    &:active svg path {
      stroke: $close-active;
    }
    &:nth-child(3) {
      margin-right: 8px;
    }
  }
  &.default {
    .modal-box {
      max-width: 620px;
      width: 100%;
      padding: 24px 40px 40px 40px;
      @include mobile {
        padding: 24px 16px 16px 16px;
      }
    }
  }
}
.grey-bg {
  background: $white-modal-bg;
}
</style>
